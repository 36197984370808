.class-info {
  .article {
    font-size: 16px;
    background-color: #fff;
    padding: 24px;
    h1 {
      font-size: 30px;
      font-weight: 700;
      word-break: break-word;
      text-align: center;
    }
    .content {
      font-weight: 400;
      line-height: 1.8;
      word-break: break-word;
    }
  }
}
.card-secondTitle {
  color: '#A8081A';
  border-color: '#A8081A';
}
.subject-step-wrap {
  margin: 0 auto !important;
  width: 80% !important;
  .subject-step_title {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .subject-step_title2 {
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
}
.step-finished {
  width: 470px;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  margin-top: 150px;
  h2 {
    color: #8c1515;
    font-size: 18px;
  }
  .summarry {
    color: #999;
    font-size: 12px;
  }
  .login-form-button {
    width: 100%;
  }
  .success-icon {
    font-size: 36px;
  }
  .success-title {
    font-size: 18px;
    color: #333 !important;
  }
}

@primary-color: #A8081A;