.login-header {
  height: 70px;
  color: #fff;
  background: #a8081a;
  line-height: 70px;
  font-size: 24px;
  text-indent: 50px;
  a,
  a:visited {
    color: #fff;
  }
}
.login-content-warp {
  background-color: #fff;
  height: 100%;

  .login-title {
    color: #8c1515;
    text-align: center;
    padding-top: 50px;
  }
  .login-wrap {
    width: 400px;
    background-color: #fff;
    margin: 0 auto;
  }
  .login-form-button {
    width: 100%;
  }
}
/* 手机屏幕大小 */
@media screen and (max-width: 750px) {
  .main-wrap {
    width: 100%;
  }
}

@primary-color: #A8081A;