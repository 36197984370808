.foot {
  margin-top: 30px;
  text-align: center;
  display: block;
  padding: 10px 50px !important;
  background-color: #001529 !important;
  color: rgba(255, 255, 255, 0.65) !important;
  p {
    margin-bottom: 0;
  }
}

@primary-color: #A8081A;