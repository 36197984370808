.step-title {
  font-weight: normal;
  text-align: center;
  font-size: 24px;
  padding-top: 15px;
  padding-bottom: 13px;
}
.step-div {
  border-color: rgba(0, 0, 0, 0.1) !important;
  margin: 0 !important;
}
.step-wrap {
  padding-top: 10px !important;
  width: 80% !important;
  margin: 0 auto 20px !important;
  background-color: #fff;
}
.subject-left {
  padding-left: 30px;
  box-sizing: border-box;
}
.subject-content-wrap {
  margin-top: 15px;
  background-color: #fff;
  clear: both;
  height: 100%;
  // min-height: calc(100vh-150px) !important;
}
.subject-content {
  background-color: #fff;
}
.step-inline {
  border-right: 1px dotted #f0f2f5;
}
.step-button-wrap {
  text-align: center;
}
.step-button {
  margin: 0 30px 0 0;
}
.wf-layout {
  height: 100%;
  background-color: #fff;
}
.step-next-wrap {
  background-color: #fff;
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.subject-step-wrap {
  margin: 0 auto !important;
  width: 80% !important;

  .subject-step_title {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .subject-step_title2 {
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
}

.step-finished {
  width: 470px;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  margin-top: 10%;

  h2 {
    color: #8c1515;
    font-size: 18px;
  }

  .summarry {
    color: #999;
    font-size: 12px;
  }

  .login-form-button {
    width: 100%;
  }

  .success-icon {
    font-size: 36px;
  }

  .success-title {
    font-size: 18px;
    color: #333 !important;
  }
}

@primary-color: #A8081A;