.banner-wrap {
  height: 600px;
}
// .ant-carousel .slick-slide {
//   text-align: center;
//   height: 600px;
//   line-height: 160px;
//   background: #364d79;
//   overflow: hidden !important;
// }

// .ant-carousel .slick-slide h3 {
//   color: #fff;
// }

// .ant-carousel .slick-slide h3 img {
//   width: 100%;
//   height: 100%;
// }

/* 手机屏幕大小 */
@media screen and (min-width: 320px) and (max-width: 1152px) {
  .banner-wrap {
    max-width: 100% !important;
    height: auto !important;
  }
  .slick-slide img {
    max-width: 100% !important;
    height: auto !important;
  }
}

@primary-color: #A8081A;