.notice-head-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  color: #8c1515;
  background: #fff;
  padding: 20px 50px 0;
}
.notice-head {
  height: 20px;
  border-left: 5px solid #8c1515;
  padding-left: 10px;
  font-size: 14px;
  color: #8c1515;
}
.notice-list-wrap {
  background-color: #fff;
  padding: 0px 50px;
  a:hover,
  a:visited {
    color: #333;
  }
}

.notice-list-item {
  display: flex;
  justify-content: space-between;
}
.divier {
  width: 100%;
  margin: 15px 0 !important;
}
.new-time {
  width: 180px;
}
.new-title {
  color: #333;
}

.article-title {
  background-color: #fff;
  width: 100%;
  height: 80px;
  line-height: 85px;
  text-align: center;
}
.article-content-wrap {
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  padding: 10px 20px;
  .article-time {
    text-align: center;
    color: #999;
  }
  .article-content {
    font-size: 14px;
  }
  .page-view-count {
    text-align: right;
  }
  .banner-wrap {
    height: 600px;
    background-size: cover;
    max-width: 1158px;
  }
  .article-bottom-wrap {
    text-align: center;
    margin-top: 30px;
    a.article-bottom-button {
      line-height: 36px;
      padding: 0 20px;
      background-color: #8c1515;
      color: #fff;
      display: inline-block;
      text-align: center;
      border-radius: 5px;
    }
    a.article-bottom-button:hover,
    a.article-bottom-button:visited {
      color: #fff;
    }
  }
}

@primary-color: #A8081A;