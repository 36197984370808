.home-nav {
  display: flex;
  color: #fff;
  width: 1152px;
  margin: 0 auto;
  justify-content: space-between;
  a,
  a:hover,
  a:visited {
    color: #fff !important;
    font-weight: bold;
  }
  .home-left {
    display: flex;
    .logo {
      width: 120px;
      line-height: 31px;
      margin: 16px 24px 16px 0;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .home-right {
    padding-right: 24px;
    a {
      color: #fff;
      font-weight: bold;
    }
  }
  .modal-wrap {
    border-radius: 12px !important;
    padding: 30px !important;
  }
}

/* 手机屏幕大小 */
@media screen and (max-width: 750px) {
 .home-nav {
  width: 100%;
}

}


@primary-color: #A8081A;