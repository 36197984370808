.home-notice_head-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding-top: 30px;
  color: #8c1515;
  background: #fff;
  .notice-head {
    height: 20px;
    border-left: 5px solid #8c1515;
    padding-left: 10px;
    font-size: 14px;
    color: #8c1515;
  }
}

.home-notice_content-wrap {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-flow: row wrap;
  .notice-time {
    background-color: #f2f2f2;
    width: 68px;
    height: 64px;
    display: inline-block;
    text-align: center;
    font-size: 13px;
    padding: 10px 5px 0;
  }

  .notice-content {
    display: inline-block;
    margin-left: 16px;
  }
  .notice-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .notice-summary {
    font-size: 12px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 400px;
  }
  a,
  a:visited {
    color: #333 !important;
  }
  a:hover {
    color: #8c1515 !important;
  }
}

@primary-color: #A8081A;